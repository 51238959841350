import React, { useEffect, useState } from 'react';
import {
    Container,
    Spinner,
    Pagination,
    PaginationItem,
    PaginationLink, Col, Label, Input, Row,
} from "reactstrap";
import { toast } from "react-toastify";
import getApi from "../../apis/get.api";

const Transactions = () => {
    document.title = "Admin | Transactions List";
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [resellerEmail, setResellerEmail] = useState('');

    const fetchResellers = (page : number, email : string) => {
        setLoading(true);
        let url = `/admin/transaction/list?page=${page}&per_page=20`;
        if (email) {
            url += `&email=${email}`;
        }
        getApi(url)
            .then((response) => {
                const data = response.data.data;
                setList(data.data);
                setCurrentPage(data.current_page);
                setLastPage(data.last_page);
            })
            .catch((error) => {
                toast.error('There was an issue while fetching the transactions list');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchResellers(currentPage, resellerEmail);
    }, [currentPage, resellerEmail]);

    const handlePageChange = (page : number) => {
        if (page !== currentPage) {
            setCurrentPage(page);
        }
    };

    const handleFilterChange = (e : any) => {
        setResellerEmail(e.target.value);
    };

    const handleFilterSubmit = (e : any) => {
        e.preventDefault();
        setCurrentPage(1);
        fetchResellers(1, resellerEmail);
    };

    const getTransactionType = (transaction : any) => {
        if(transaction.userType === 3){
            if(transaction.transactionCustomType == 'redeem'){
                return 'REDEEM'
            }else if(transaction.transactionCustomType == 'settlement'){
                return 'SETTLE'
            }else if(transaction.transactionCustomType == 'payout'){
                return 'PAYOUT'
            }else if(transaction.transactionType == 'fund'){
                return 'REDEEM'
            }else if(transaction.transactionType == 'deduct'){
                return 'SETTLE'
            }
        }else if(transaction.userType === 2 && transaction.transactionType === 'fund'){
            return 'WALLET FUND'
        }else if(transaction.userType === 2 && transaction.transactionType === 'deduct'){
            return 'GENERATE'
        }
        return '';
    }

    return (
        <div className="page-content">
            <Container fluid={true}>
                <div className="rounded-3">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header fs-4">
                                    In this page you will find all transactions list that are registered
                                </div>
                                <div className="card-body">
                                    <Row className=" align-items-center">
                                        <Col lg={4}>
                                            <Label className="visually-hidden" htmlFor="inlineFormInputGroupUsername">Username</Label>
                                            <div className="input-group">
                                                <div className="input-group-text">Reseller Email</div>
                                                <Input type="text" className="form-control" id="resellerEmail" value={resellerEmail} onChange={handleFilterChange} placeholder="Reseller Email" />
                                            </div>
                                        </Col>
                                        <Col xs={8} className={"text-end"}>
                                            <button type="submit" className="btn btn-primary" onClick={handleFilterSubmit}>Search</button>
                                        </Col>
                                    </Row>
                                    <div className="table-responsive table-card mt-4">
                                        <table className="table text-center align-middle table-nowrap table-striped-columns mb-0">
                                            <thead className="table-light">
                                            <tr>
                                                <th scope="col" className={"text-center"}>Unique ID</th>
                                                <th scope="col">Transaction ID</th>
                                                <th scope="col">Username</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">User Type</th>
                                                <th scope="col">Transaction Type</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Currency</th>
                                                <th scope="col">Amount Before</th>
                                                <th scope="col">Amount After</th>
                                                <th scope="col">Transaction Date</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {loading && (
                                                <tr>
                                                    <td className="text-center p-4" colSpan={11}><Spinner /></td>
                                                </tr>
                                            )}
                                            {list && list.length > 0 && list.map((transaction : any) => (
                                                <tr key={transaction.transactionUniqueId} className={"fw-bold"}>
                                                    <td className={"text-center"}><span
                                                        className="fw-medium">#{transaction.transactionUniqueId}</span>
                                                    </td>
                                                    <td>{transaction.transactionId}</td>
                                                    <td>{transaction.userName}</td>
                                                    <td>{transaction.userEmail}</td>
                                                    <td>{transaction.userType == 2 ? 'Reseller' : 'Merchant'}</td>
                                                    <td>{getTransactionType(transaction)}</td>
                                                    <td>{transaction.transactionAmount}</td>
                                                    <td>{transaction.transactionCurrency.toString().toUpperCase()}</td>
                                                    <td>{transaction.transactionAmountBefore}</td>
                                                    <td>{transaction.transactionAmountAfter}</td>
                                                    <td>{transaction.transactionDate}</td>
                                                </tr>
                                            ))}
                                            {!loading && list.length === 0 && (
                                                <tr>
                                                    <td className="text-center p-4" colSpan={11}>No Transactions Found</td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="card-footer d-flex justify-content-center mt-4 border-top-0">
                                        <Pagination>
                                            {/* Previous Button */}
                                            <PaginationItem disabled={currentPage === 1}>
                                                <PaginationLink previous
                                                                onClick={() => handlePageChange(currentPage - 1)}/>
                                            </PaginationItem>

                                            {/* Pagination Numbers */}
                                            {[...Array(lastPage)].map((_, i) => {
                                                // Display first two pages, last two pages, and pages near the current page
                                                const pageNumber = i + 1;
                                                const isNearCurrentPage = Math.abs(currentPage - pageNumber) <= 2;
                                                const isFirstOrLastPage = pageNumber === 1 || pageNumber === lastPage;

                                                if (isNearCurrentPage || isFirstOrLastPage) {
                                                    return (
                                                        <PaginationItem key={pageNumber}
                                                                        active={currentPage === pageNumber}>
                                                            <PaginationLink
                                                                onClick={() => handlePageChange(pageNumber)}>
                                                                {pageNumber}
                                                            </PaginationLink>
                                                        </PaginationItem>
                                                    );
                                                }
                                                // Ellipsis for skipped pages
                                                if (pageNumber === 2 && currentPage > 4) {
                                                    return <PaginationItem key="ellipsis1"
                                                                           disabled><PaginationLink>...</PaginationLink></PaginationItem>;
                                                }
                                                if (pageNumber === lastPage - 1 && currentPage < lastPage - 3) {
                                                    return <PaginationItem key="ellipsis2"
                                                                           disabled><PaginationLink>...</PaginationLink></PaginationItem>;
                                                }

                                                return null; // Don't render other page numbers
                                            })}

                                            {/* Next Button */}
                                            <PaginationItem disabled={currentPage === lastPage}>
                                                <PaginationLink next onClick={() => handlePageChange(currentPage + 1)}/>
                                            </PaginationItem>
                                        </Pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Transactions;