import React, {useEffect, useState} from 'react';
import {Container, Spinner,} from 'reactstrap';

import Section1 from "../../project-pages/dashboard/Section1";
import CounterWidget from "../../project-pages/dashboard/CounterWidget";
import getApi from "../../apis/get.api";
import {toast} from "react-toastify";

const Dashboard = (props : any) => {
    document.title = "Dashboard";
    const [vouchersAmountsReport,setVouchersAmountReport] = useState<any>(null)
    const [balance,setBalance] = useState<number>(0)
    const [vouchers,setVouchers] = useState<any>([])

    useEffect(() => {
        getApi('/admin/dashboard').then((response) => {
            setVouchersAmountReport({
                'activeSum' : response.data.data.activeSum,
                'redeemedSum' : response.data.data.redeemedSum,
                'expiredSum' : response.data.data.expiredSum,
                'deactivatedSum' : response.data.data.deactivatedSum,
            })
            setBalance(response.data.data.balance)
        }).catch((error) => {
            toast.error('There was an issue while loading the page, please refresh the page')
        })
        getApi('/admin/voucher/list?per_page=10').then((response) => {
            let vouchersData : any = [];
            response.data.data.data.forEach((voucher : any) => {
                vouchersData.push({
                    voucherId : voucher.voucherId,
                    voucherCode : voucher.voucherCode,
                    voucherOrderId : voucher.voucherOrderId,
                    voucherAmount : voucher.voucherAmount,
                    voucherStatus : voucher.voucherStatus,
                    voucherExpiry : voucher.voucherExpiryDate,
                    voucherResellerName : voucher.resellerName,
                    voucherMerchantName : voucher.merchantName,
                    voucherCurrency : voucher.voucherCurrency,
                    generatingMerchantName : voucher.generatingMerchantName,
                    redeemingMerchantName : voucher.redeemingMerchantName
                })
            })
            setVouchers(vouchersData)
        }).catch((error) => {
            console.log(error)
            toast.error('There was an issue while loading the page, please refresh the page')
        })
    }, []);

    const changeDate = (props : any) => {
        const fromDate = props[0]?.toISOString().split('T')[0]; // Get yyyy-mm-dd format
        const toDate = props[1]?.toISOString().split('T')[0]; // Get yyyy-mm-dd format
        getApi(`/admin/dashboard?from=${fromDate}&to=${toDate}`).then((response) => {
            setVouchersAmountReport({
                'activeSum' : response.data.data.activeSum,
                'redeemedSum' : response.data.data.redeemedSum,
                'expiredSum' : response.data.data.expiredSum,
                'deactivatedSum' : response.data.data.deactivatedSum,
            })
            setBalance(response.data.data.balance)
        }).catch((error) => {
            toast.error('There was an issue while loading the page, please refresh the page')
        })
    }
    return <div className="page-content">
        <Container fluid={true}>
            <div className="rounded-3">
                {!vouchersAmountsReport && <div className={"p-5 text-center"}><Spinner/></div>}
                {vouchersAmountsReport && <CounterWidget voucherAmounts={vouchersAmountsReport}/>}
                {vouchersAmountsReport && <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                Latest 10 Generated Vouchers By Resellers
                            </div>
                            <div className="table-responsive table-card card-body">
                                <table className="table align-middle table-nowrap table-striped-columns mb-0">
                                    <thead className="table-light">
                                    <tr className={"text-center"}>
                                        <th scope="col" className={"text-start"}>Voucher ID</th>
                                        <th scope="col">Reseller</th>
                                        <th scope="col">Merchant</th>
                                        <th scope="col">Redeemed Merchant</th>
                                        <th scope="col">Voucher Code</th>
                                        <th scope="col">Voucher Expiration</th>
                                        <th scope="col">Voucher Amount</th>
                                        <th scope="col">Voucher Currency</th>
                                        <th scope="col">Voucher Status</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {vouchers && vouchers.length > 0 && vouchers.map((voucher: any,index : number) => {
                                        return <tr key={index} className={"text-center fw-bold"}>
                                            <td className={"text-start"}><span
                                                className="fw-medium">#{voucher.voucherId}</span></td>
                                            <td>{voucher.voucherResellerName ?? 'Payout'}</td>
                                            <td>{voucher.generatingMerchantName}</td>
                                            <td>{voucher.redeemingMerchantName ?? 'Not Redeemed'}</td>
                                            <td>{voucher.voucherCode}</td>
                                            <td>{voucher.voucherExpiry}</td>
                                            <td>{voucher.voucherAmount}</td>
                                            <td>{voucher.voucherCurrency.toString().toUpperCase()}</td>
                                            <td>{voucher.voucherStatus.toString().toUpperCase()}</td>
                                        </tr>
                                    })}
                                    {vouchers && vouchers.length === 0 && <tr>
                                        <td className="text-center p-4" colSpan={9}>No Vouchers Created Yet</td>
                                    </tr>}
                                    {!vouchers && <tr>
                                        <td className="text-center p-4" colSpan={9}><Spinner/></td>
                                    </tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </Container>
    </div>
};

export default Dashboard;