import React from 'react';
import CountUp from "react-countup";
import { Card, CardBody, Col, Row } from 'reactstrap';

const CounterWidget = (voucherAmounts: any) => {
    return (
        <React.Fragment>
            <Row>
                <Col xl={3} md={6}>
                    <Card className="card-animate">
                        <CardBody>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">Active Value</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary"><span className="counter-value" data-target={voucherAmounts.activeSum}>
                                    <CountUp
                                        start={0}
                                        prefix={"$"}
                                        suffix={""}
                                        separator={","}
                                        end={voucherAmounts.voucherAmounts.activeSum}
                                        decimals={0}
                                        duration={4}
                                    />
                                    </span></h4>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xl={3} md={6}>
                    <Card className="card-animate">
                        <CardBody>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-uppercase fw-bold text-muted text-truncate mb-0">Redeemed Value</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                    <h4 className="fs-22 fw-bold ff-secondary"><span className="counter-value" data-target={voucherAmounts.redeemedSum}>
                                    <CountUp
                                        start={0}
                                        prefix={"$"}
                                        suffix={""}
                                        separator={","}
                                        end={voucherAmounts.voucherAmounts.redeemedSum}
                                        decimals={0}
                                        duration={4}
                                    />
                                    </span></h4>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xl={3} md={6}>
                    <Card className="card-animate">
                        <CardBody>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">Expired Value </p>
                                </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary"><span className="counter-value" data-target={voucherAmounts.expiredSum}>
                                    <CountUp
                                        start={0}
                                        prefix={"$"}
                                        suffix={""}
                                        separator={","}
                                        end={voucherAmounts.voucherAmounts.expiredSum}
                                        decimals={0}
                                        duration={4}
                                    />
                                    </span></h4>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xl={3} md={6}>
                    <Card className="card-animate">
                        <CardBody>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">Deactivated Value</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary"><span className="counter-value" data-target={voucherAmounts.deactivatedSum}>
                                    <CountUp
                                        start={0}
                                        prefix={"$"}
                                        suffix={""}
                                        separator={","}
                                        end={voucherAmounts.voucherAmounts.deactivatedSum}
                                        decimals={0}
                                        duration={4}
                                    />
                                    </span></h4>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default CounterWidget;