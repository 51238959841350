import React, { useEffect, useState } from "react";
import {
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    Spinner,
    TabContent,
    TabPane,
    UncontrolledButtonDropdown,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import classnames from "classnames";
import { Link, useParams, useRoutes } from "react-router-dom";
import Transactions from "./merchantComponents/Settlements";
import Vouchers from "./merchantComponents/Vouchers";
import getApi from "../../../apis/get.api";
import { toast } from "react-toastify";
import {MerchantInterface} from "../../../Interfaces/Interface";
import putApi from "../../../apis/put.api";
import PendingSettlementRequests from "./merchantComponents/PendingSettlementRequests";
import Settlements from "./merchantComponents/Settlements";
import Select from "react-select";

const EditPage = () => {
    document.title = "Admin | Merchant Edit";
    const [activeTab, setActiveTab] = useState("1");
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [countries, setCountries] = useState<any>(null);
    const settle = [
        {
            label: 'Yes',
            value: '1',
        },
        {
            label: 'No',
            value: '0',
        }
    ];
    const [defaultCountry, setDefaultCountry] = useState<any>();
    const [defaultSettle, setDefaultSettle] = useState<any>(null);
    const [merchantInformation, setMerchantInformation] = useState<MerchantInterface>({
        merchantId : 0,
        merchantStatus : '',
        merchantUsername: "",
        merchantEmail: "",
        merchantCountryId: 0,
        merchantCountryName : "",
        merchantWebsiteUrl: "",
        merchantPhoneNumber: "",
        merchantCommission: 0,
        merchantPayoutCommission : 0,
        merchantBalance : 0,
        merchantAutoSettleNoneClvrPay : 0
    });

    const tabChange = (tab: any) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    useEffect(() => {
        getApi(`/admin/merchant/${id}`)
            .then((response: any) => {
                const profileData = response.data.data;
                setMerchantInformation(profileData);
                setDefaultSettle({
                    label : profileData.merchantAutoSettleNoneClvrPay == '0' ? 'No' : 'Yes',
                    value :  profileData.merchantAutoSettleNoneClvrPay
                })
                getApi('/common/get/countries').then((response: any) => {
                    const countriesList = response.data.data.map((country: any) => ({
                        label: country.name,
                        value: country.id,
                    }));

                    // Loop through countriesList directly instead of countriesList.array
                    countriesList.forEach((country: any) => {
                        if (country.value === profileData.merchantCountryId) {
                            setDefaultCountry({
                                label: country.label,
                                value: country.value // Correct the value here too
                            });
                        }
                    });

                    setCountries(countriesList);
                })
                .catch((error) => {
                    toast.error('General error occurred');
                    throw error;
                });
            })
            .catch((error: any) => {
                toast.error("There was an issue while fetching merchant information, please reload the page");
            });
    }, []);
    

    const merchantStatusUpdate = (status : string) => {
        putApi(`/admin/merchant/edit/${id}`,{status : status}).then(response => {
            toast.success('Merchant status has been updated')
            window.location.reload()
        }).catch(error => {
            toast.error('Something went wrong while processing your request')
        })
    }

    // Function to render dropdown options based on merchantStatus
    const renderDropdownOptions = () => {
        switch (merchantInformation?.merchantStatus) {
            case "a": // Approved
                return (
                    <>
                        <DropdownItem onClick={() => {merchantStatusUpdate('r')}}> Reject </DropdownItem>
                        <DropdownItem onClick={() => {merchantStatusUpdate('b')}}> Block </DropdownItem>
                    </>
                );
            case "b": // Blocked
                return (
                    <>
                        <DropdownItem onClick={() => {merchantStatusUpdate('r')}}> Reject </DropdownItem>
                        <DropdownItem onClick={() => {merchantStatusUpdate('a')}}> Approve </DropdownItem>
                    </>
                );
            case "r": // Rejected
                return (
                    <>
                        <DropdownItem onClick={() => {merchantStatusUpdate('a')}}> Approve </DropdownItem>
                        <DropdownItem onClick={() => {merchantStatusUpdate('b')}}> Block </DropdownItem>
                    </>
                );
            case "p": // Pending
                return (
                    <>
                        <DropdownItem onClick={() => {merchantStatusUpdate('r')}}> Reject </DropdownItem>
                        <DropdownItem onClick={() => {merchantStatusUpdate('b')}}> Block </DropdownItem>
                        <DropdownItem onClick={() => {merchantStatusUpdate('a')}}> Approve </DropdownItem>
                    </>
                );
            default:
                return null;
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMerchantInformation({
            ...merchantInformation,
            [e.target.name]: e.target.value,
        });
    };


    const handleUpdate = () => {
        setIsLoading(true); // Start loading
        putApi(`/admin/merchant/update/${id}`, merchantInformation).then(() => {
            toast.success("Merchant profile updated successfully");
        }).catch(() => {
            toast.error("Failed to update merchant profile");
        }).finally(() => {
            setIsLoading(false); // Stop loading
        });
    };


    const changeCountry = (element: any) => {
        setMerchantInformation({
            ...merchantInformation,
            ['merchantCountryId']: element.value,
        });
    };
    
    const changeAutoSettle = (element: any) => {
        setMerchantInformation({
            ...merchantInformation,
            ['merchantAutoSettleNoneClvrPay']: element.value,
        });
    };

    return (
        <div className="page-content">
            <BreadCrumb title={`${merchantInformation?.merchantUsername}`} pageTitle="Merchants List" url={"/merchant/list"} />
            <Container fluid={true}>
                <div className="d-flex justify-content-between w-100 mb-3">
                    <div>
                        {merchantInformation && merchantInformation.merchantStatus === "p" && (
                            <div className="alert-border-left shadow alert alert-info alert-dismissible fade show" role="alert">
                                <i className="ri-airplay-line me-3 align-middle fs-16"></i>
                                <strong>Pending Application </strong> - This merchant has pending application, in order for him to use our
                                service his application has to be approved
                            </div>
                        )}
                        {merchantInformation && merchantInformation.merchantStatus === "b" && (
                            <div className="alert-border-left shadow alert alert-danger alert-dismissible fade show" role="alert">
                                <i className="ri-airplay-line me-3 align-middle fs-16"></i>
                                <strong>Blocked Application </strong> - This merchant has blocked application, in order for him to use our
                                service his application has to be approved
                            </div>
                        )}
                        {merchantInformation && merchantInformation.merchantStatus === "r" && (
                            <div className="alert-border-left shadow alert alert-danger alert-dismissible fade show" role="alert">
                                <i className="ri-airplay-line me-3 align-middle fs-16"></i>
                                <strong>Rejected Application </strong> - This merchant has rejected application, in order for him to use our
                                service his application has to be approved
                            </div>
                        )}
                    </div>

                </div>

                <div className="d-flex gap-3 w-100">
                    <Card className="w-100">
                        <CardHeader>
                            <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === "1" })}
                                        onClick={() => {
                                            tabChange("1");
                                        }}
                                    >
                                        <i className="fas fa-home"></i>
                                        Generated Vouchers By Resellers
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        to="#"
                                        className={classnames({ active: activeTab === "2" })}
                                        onClick={() => {
                                            tabChange("2");
                                        }}
                                        type="button"
                                    >
                                        <i className="far fa-user"></i>
                                        Settlements
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        to="#"
                                        className={classnames({ active: activeTab === "3" })}
                                        onClick={() => {
                                            tabChange("3");
                                        }}
                                        type="button"
                                    >
                                        <i className="far fa-envelope"></i>
                                        Pending Settlement Requests
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        to="#"
                                        className={classnames({ active: activeTab === "4" })}
                                        onClick={() => {
                                            tabChange("4");
                                        }}
                                        type="button"
                                    >
                                        <i className="far fa-envelope"></i>
                                        Profile
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        to="#"
                                        className={classnames({ active: activeTab === "5" })}
                                        onClick={() => {
                                            tabChange("5");
                                        }}
                                        type="button"
                                    >
                                        <i className="far fa-envelope"></i>
                                        Actions
                                    </NavLink>
                                </NavItem>

                            </Nav>
                        </CardHeader>
                        {merchantInformation && merchantInformation.merchantId > 0 && <CardBody className="p-4">
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    {merchantInformation && <Vouchers merchantId={merchantInformation.merchantId} />}
                                </TabPane>
                                <TabPane tabId="2">
                                    {merchantInformation && <Settlements merchantId={merchantInformation.merchantId} />}
                                </TabPane>
                                <TabPane tabId="3">
                                    {merchantInformation && <PendingSettlementRequests merchantId={merchantInformation.merchantId}/>}
                                </TabPane>
                                <TabPane tabId="4">
                                {merchantInformation && (
                                        <Row className={"gap-3"}>
                                            <Col md={12} xs={12} lg={4} xl={3}>
                                                <Label for="merchantUsername" className="form-label">Merchant Name</Label>
                                                <Input
                                                    type="text"
                                                    name="merchantUsername"
                                                    className="form-control"
                                                    value={merchantInformation.merchantUsername}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter merchant name"
                                                />
                                            </Col>
                                            <Col md={12} xs={12} lg={4} xl={3}>
                                                <Label for="merchantEmail" className="form-label">Merchant Email</Label>
                                                <Input
                                                    type="email"
                                                    name="merchantEmail"
                                                    className="form-control"
                                                    value={merchantInformation.merchantEmail}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter merchant email"
                                                />
                                            </Col>
                                            {defaultCountry && <Col md={12} xs={12} lg={4} xl={3}>
                                                <Label for="merchantCountryName" className="form-label">Merchant Country</Label>
                                                <Select
                                                    options={countries}
                                                    id="choices-single-default"
                                                    className="js-example-basic-single mb-0"
                                                    name="country_id"
                                                    defaultValue={defaultCountry}
                                                    onChange={changeCountry}
                                                />
                                            </Col>}
                                            <Col md={12} xs={12} lg={4} xl={3}>
                                                <Label for="merchantWebsiteUrl" className="form-label">Merchant Website</Label>
                                                <Input
                                                    type="text"
                                                    name="merchantWebsiteUrl"
                                                    className="form-control"
                                                    value={merchantInformation.merchantWebsiteUrl}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter website"
                                                />
                                            </Col>
                                            <Col md={12} xs={12} lg={4} xl={3}>
                                                <Label for="merchantPhoneNumber" className="form-label">Merchant Phone Number</Label>
                                                <Input
                                                    type="text"
                                                    name="merchantPhoneNumber"
                                                    className="form-control"
                                                    value={merchantInformation.merchantPhoneNumber}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter phone number"
                                                />
                                            </Col>
                                            <Col md={12} xs={12} lg={4} xl={3}>
                                                <Label for="merchantCommission" className="form-label">Merchant Settlement Commission</Label>
                                                <Input
                                                    type="text"
                                                    name="merchantCommission"
                                                    className="form-control"
                                                    value={merchantInformation.merchantCommission}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter commission"
                                                />
                                            </Col>
                                            <Col md={12} xs={12} lg={4} xl={3}>
                                                <Label for="merchantPayoutCommission" className="form-label">Merchant Payout Commission</Label>
                                                <Input
                                                    type="text"
                                                    name="merchantPayoutCommission"
                                                    className="form-control"
                                                    value={merchantInformation.merchantPayoutCommission}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter payout commission"
                                                />
                                            </Col>
                                            {defaultSettle && <Col md={12} xs={12} lg={4} xl={3}>
                                                <Label for="merchantAutoSettleNoneClvrPay" className="form-label">Merchant Auto ClvrPay Settle</Label>
                                                <Select
                                                    options={settle}
                                                    id="merchantAutoSettleNoneClvrPay"
                                                    className="js-example-basic-single mb-0"
                                                    name="merchantAutoSettleNoneClvrPay"
                                                    defaultValue={defaultSettle}
                                                    onChange={changeAutoSettle}
                                                />
                                            </Col>}
                                            <Col md={12} xs={12} lg={4} xl={3}>
                                                <Label for="merchantPayoutCommission" className="form-label">Merchant Wallet Balance</Label>
                                                <Input
                                                    type="text"
                                                    name="merchantBalance"
                                                    className="form-control"
                                                    value={merchantInformation.merchantBalance ?? 0}
                                                    readOnly={true}
                                                    disabled={true}
                                                />
                                            </Col>
                                        </Row>
                                    )}
                                    <div className="hstack gap-2 justify-content-end">
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={handleUpdate}
                                            disabled={isLoading} // Disable button while loading
                                        >
                                            {isLoading ? <Spinner size="sm" /> : "Update"} {/* Loader inside button */}
                                        </button>
                                    </div>
                                </TabPane>
                                <TabPane tabId="5">
                                    <ButtonGroup>
                                        <UncontrolledButtonDropdown id="btnGroupDrop1">
                                            <DropdownToggle color="primary" caret size={"sm"}>
                                                Merchant Actions
                                            </DropdownToggle>
                                            <DropdownMenu>{renderDropdownOptions()}</DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </ButtonGroup>
                                </TabPane>
                            </TabContent>
                        </CardBody>}
                    </Card>
                </div>
            </Container>
        </div>
    );
};

export default EditPage;